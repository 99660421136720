// pages/ReportingPage.tsx
import React from "react";
import Graph from "../components/charts/Graph"
import ReportingLayout from "../layouts/ReportingLayout";
import PieChartComponent from "../components/charts/PieChartComponent";
import {PieSeriesType} from "@mui/x-charts";
import {Grid2} from "@mui/material";
import "./ReportingPage.css"
import CypherAndSQLForm from "../components/input/CypherAndSQLForm";
import SideMenu from "../components/common/SideMenu";

// Structure for graph nodes and links
const graphData = {
    nodes: [
        // Add all nodes with "identity" as group
        { id: "0", name: "Dustin Henderson", group: "identity", icon: "user-solid.svg" },
        { id: "1", name: "Mike Wheeler", group: "identity", icon: "user-solid.svg"},
        { id: "2", name: "Jim Hopper", group: "identity", icon: "user-solid.svg" },
        { id: "3", name: "Azure-Connect", group: "identity", icon: "server-solid.svg" },

        // Add all nodes with "group" as group
        { id: "4", name: "Sales", group: "group", icon: "users-solid.svg" },
        { id: "5", name: "Engineering", group: "group", icon: "users-solid.svg" },
        { id: "6", name: "Finance", group: "group", icon: "users-solid.svg" },
        { id: "7", name: "Admin", group: "group", icon: "users-solid.svg" },

        // Add all nodes with "role" as group
        { id: "8",  name: "Sales-Read-Only", group: "role", icon: "r-solid.svg" },
        { id: "9", name: "Admin-User-Role", group: "role", icon: "r-solid.svg" },
    ],
    links: [
        // Identity to group
        { source: "0", target: "4" },
        { source: "0", target: "5" },
        { source: "0", target: "6" },
        { source: "1", target: "5" },
        { source: "2", target: "6" },
        { source: "3", target: "7" },

        // Group to role
        { source: "4", target: "9" },
        { source: "6", target: "8" },
        { source: "5", target: "9" },
        { source: "5", target: "8" },
        { source: "7", target: "9" },
    ],
};

const lifecyclePie: PieSeriesType = {
    type: "pie",
    data: [
        {id: 0, value: 1, label: "Joiner"},
        {id: 1, value: 2, label: "Mover"},
        {id: 2, value: 3, label: "Leaver"}
    ],
};

const moverPie: PieSeriesType = {
    type: "pie",
    data: [
        {id: 0, value: 12, label: "Sales"},
        {id: 1, value: 124, label: "Engineering"},
        {id: 2, value: 243, label: "Finance"}
    ],
}

const ReportingPage: React.FC = () => {
    return (
        <ReportingLayout>
            <Grid2 container spacing={2} sx={{ justifyContent: "center" }} >
                <Grid2 size={6} className={"grid-container"}>
                    <PieChartComponent series={lifecyclePie} title={"Lifecycle"} />
                </Grid2>
                <Grid2 size={6} className={"grid-container"}>
                    <PieChartComponent series={moverPie} title={"Mover Events"} />
                </Grid2>
                {/*<Grid2 size={2} className={"grid-container"}>*/}
                {/*    <CypherAndSQLForm/>*/}
                {/*</Grid2>*/}
                <Grid2 size={10} className={"grid-container"}>
                    <Graph graphData={graphData} />
                </Grid2>
            </Grid2>
        </ReportingLayout>
    );
};

export default ReportingPage;