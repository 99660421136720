// src/routes/AppRoutes.tsx

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HomePage from '../pages/HomePage';
import LoginPage from '../pages/LoginPage';
import DashboardPage from '../pages/DashboardPage';
import ProfilePage from '../pages/ProfilePage';
import SettingsPage from '../pages/SettingsPage';
import GraphPage from '../pages/ComplexGraphPage';
import ComplexGraphPage from '../pages/ComplexGraphPage'; // New import
import NotFoundPage from '../pages/NotFoundPage';
import ReportingPage from '../pages/ReportingPage';
import PrivateRoute from './PrivateRoute';
import CypherAndSQLForm from "../components/input/CypherAndSQLForm";
import ConfigurationEnginePage from "../pages/ConfigurationEnginePage";

const AppRoutes: React.FC = () => (
    <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />

        {/* Protected Routes */}
        <Route element={<PrivateRoute />}>
            <Route path="/dashboard" element={<ReportingPage />} />
            <Route path="/configuration" element={<ConfigurationEnginePage />}/>
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/settings" element={<SettingsPage />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
    </Routes>
);

export default AppRoutes;