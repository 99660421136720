import React from 'react';
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import UserMenu from './UserMenu';

const { Header } = Layout;

const AppHeader: React.FC = () => {
    const { isAuthenticated } = useAuth();

    return (
        <Header
            className="header"
            style={{
                backgroundColor: '#001529',  // Keeps a solid dark background
                boxShadow: 'none',           // Removes extra rectangle or shadow
                padding: '0 20px'
            }}
        >
            <div className="logo">
                <img
                    src="/favicon.png"
                    alt="Logo"
                    style={{
                        width: '40px',      // Adjust as needed
                        height: '40px',     // Adjust as needed
                        objectFit: 'contain'
                    }}
                />
            </div>
            <Menu theme="dark" mode="horizontal" selectable={false} style={{ lineHeight: '64px' }}>
                <Menu.Item key="home">
                    <Link to="/">Home</Link>
                </Menu.Item>
                {isAuthenticated && (
                    <Menu.Item key="dashboard">
                        <Link to="/dashboard">Dashboard</Link>
                    </Menu.Item>
                )}
                {isAuthenticated && (
                    <Menu.Item key="configuration">
                        <Link to="/configuration">Configuration Engine</Link>
                    </Menu.Item>
                )}
                <Menu.Item key="profile" style={{ marginLeft: 'auto' }}>
                    <UserMenu />
                </Menu.Item>
            </Menu>
        </Header>
    );
};

export default AppHeader;
