import {PieChart} from "@mui/x-charts/PieChart";
import Box from "@mui/material/Box";
import * as React from "react";
import {PieItemIdentifier, PieSeriesType} from "@mui/x-charts";
import TableDialog from "../common/TableDialog";
import {Grid2, Typography} from "@mui/material";
import {GridColDef} from "@mui/x-data-grid";

interface PieChartComponentProps {
    series: PieSeriesType;
    title: string;
}

const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "firstName", headerName: "First name", width: 130 },
    { field: "lastName", headerName: "Last name", width: 130 },
    {
        field: "age",
        headerName: "Age",
        type: "number",
        width: 90,
    },
    {
        field: "fullName",
        headerName: "Full name",
        description: "This column has a value getter and is not sortable.",
        sortable: false,
        width: 160,
        valueGetter: (value, row) => `${row.firstName || ""} ${row.lastName || ""}`,
    },
];

const rows = [
    { id: 1, lastName: "Snow", firstName: "Jon", age: 35 },
    { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42 },
    { id: 3, lastName: "Lannister", firstName: "Jaime", age: 45 },
    { id: 4, lastName: "Stark", firstName: "Arya", age: 16 },
    { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: null },
    { id: 6, lastName: "Melisandre", firstName: null, age: 150 },
    { id: 7, lastName: "Clifford", firstName: "Ferrara", age: 44 },
    { id: 8, lastName: "Frances", firstName: "Rossini", age: 36 },
    { id: 9, lastName: "Roxie", firstName: "Harvey", age: 65 },
];

const PieChartComponent: React.FC<PieChartComponentProps> = ({series, title}) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // Opens the TableDialog
    const handleItemClick = (e: React.MouseEvent<SVGElement>, item: PieItemIdentifier) => {
        setOpen(true);
    }

    return (
        <Grid2 container direction="column" alignItems="center">
            <Typography variant="h4" align="center">{title}</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 400}}>
                <PieChart
                    series={[
                        {
                            data: series.data,
                        }
                    ]}
                    onItemClick={handleItemClick}
                    height={200}
                    slotProps={{legend: {hidden: false, position: {vertical: "bottom", horizontal: "right"}}}}
                />
            </Box>
            <TableDialog isOpen={open} handleClose={handleClose} handleOpen={handleOpen} data={{rows: rows, columns: columns}} />
        </Grid2>
    );
}


export default PieChartComponent;