import React from 'react';
import styled from 'styled-components';
import CreateSystemForm from './CreateSystemForm';

const BoundedContainer = styled.div`
  border: 1px solid #f0f0f0; /* Extremely light grey border */
  border-radius: 8px;       /* Curved edges */
  padding: 16px;            /* Optional: adds some spacing inside the border */
  margin: 16px;             /* Optional: adds some spacing outside the container */
`;

const SystemEntry: React.FC = () => {
  return (
    <BoundedContainer>
      <CreateSystemForm />
    </BoundedContainer>
  );
};

export default SystemEntry;
