// pages/DashboardPage.tsx
import React, {useContext, useEffect} from 'react';
import { Layout } from 'antd';
import MainLayout from '../layouts/MainLayout';
import SideMenu from '../components/common/SideMenu';
import {LoadingContext} from "../context/LoadingContext";
import SystemEntry from "../components/common/SystemEntry";
import MappingForm from "../components/common/MappingForm";

const { Content, Sider } = Layout;

const ConfigurationEnginePage: React.FC = () => {
    const { setLoading } = useContext(LoadingContext);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                // Simulate data fetching
                await new Promise((resolve) => setTimeout(resolve, 2000));
                // Replace with actual API call
            } catch (error) {
                console.error('Error fetching configuration engine data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [setLoading]);

    return (
        <MainLayout>
            <Layout style={{padding: '0 24px 24px'}}>
                <Content
                    className="site-layout-background"
                    style={{
                        padding: 24,
                        margin: 0,
                        minHeight: 280,
                    }}
                >
                    <h1>Configuration Engine</h1>
                    <SystemEntry />
                    <MappingForm />
                </Content>
            </Layout>

        </MainLayout>
    )
}

export default ConfigurationEnginePage;