import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, message } from 'antd';
import styled from 'styled-components';
import axios from 'axios';

const { Option } = Select;

const BoundedContainer = styled.div`
    border: 1px solid #f0f0f0;  /* Extremely light grey border */
    border-radius: 8px;         /* Curved edges */
    padding: 16px;
    margin: 16px;
`;

interface System {
    id: string;
    name: string;
}

interface MappingEntry {
    source: string;
    target: string;
}

interface MappingFormValues {
    systemId: string;
    pointId: string; // Hidden field; generated/returned from API
    jsonData: MappingEntry[];
}

// Simulated pre-loaded data for demonstration; replace with actual API calls.
const defaultSystems: System[] = [
    { id: '1', name: 'System A' },
    { id: '2', name: 'System B' },
];

const defaultMapping: MappingFormValues = {
    systemId: '1',
    pointId: '123e4567-e89b-12d3-a456-426614174000',
    jsonData: [{ source: 'sourceSystem', target: 'targetName' }],
};

const MappingPage: React.FC = () => {
    const [form] = Form.useForm<MappingFormValues>();
    const [systems, setSystems] = useState<System[]>([]);
    const [mappingExists, setMappingExists] = useState<boolean>(false);

    // Load systems from API (or simulated data)
    useEffect(() => {
        // Replace with axios.get('{{url}}/api/system/all') in production.
        setSystems(defaultSystems);
    }, []);

    // Handle system selection and load mapping data if available.
    const handleSystemChange = (systemId: string) => {
        // Reset mapping fields.
        form.setFieldsValue({
            systemId,
            pointId: '', // hidden field
            jsonData: [{ source: '', target: '' }],
        });

        // Simulated API call to load mapping for the selected system.
        // Replace with: axios.get(`{{url}}/api/mapping/${systemId}`)
        if (systemId === defaultMapping.systemId) {
            setMappingExists(true);
            form.setFieldsValue({
                pointId: defaultMapping.pointId,
                jsonData: defaultMapping.jsonData.length > 0 ? defaultMapping.jsonData : [{ source: '', target: '' }],
            });
        } else {
            setMappingExists(false);
            form.setFieldsValue({
                pointId: '', // remains empty if creating
                jsonData: [{ source: '', target: '' }],
            });
        }
    };

    const onFinish = (values: MappingFormValues) => {
        console.log('Submitted mapping data:', values);
        if (mappingExists) {
            console.log('Performing update mapping operation');
            // Uncomment when API is available:
            // axios.post('{{url}}/api/mapping/update', values)
            //   .then(response => console.log(response.data))
            //   .catch(error => console.error(error));
        } else {
            console.log('Performing create mapping operation');
            // Uncomment when API is available:
            // axios.post('{{url}}/api/mapping/create', values)
            //   .then(response => console.log(response.data))
            //   .catch(error => console.error(error));
        }
    };

    // @ts-ignore
    return (
        <BoundedContainer>
            <Form<MappingFormValues>
                form={form}
                layout="vertical"
                initialValues={{
                    systemId: '',
                    pointId: '',
                    jsonData: [{ source: '', target: '' }],
                }}
                onFinish={onFinish}
            >
                {/* System selection */}
                <Form.Item
                    label="System"
                    name="systemId"
                    rules={[{ required: true, message: 'Please select a system!' }]}
                >
                    <Select placeholder="Select a system" onChange={handleSystemChange}>
                        {systems.map(system => (
                            <Option key={system.id} value={system.id}>
                                {system.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                {/* Hidden Point ID field */}
                <Form.Item name="pointId" hidden>
                    <Input type="hidden" />
                </Form.Item>

                {/* Dynamic list of mapping entries */}
                <Form.List name="jsonData">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map((field, index) => (
                                <div
                                    key={field.key}
                                    style={{ display: 'flex', marginBottom: 8, alignItems: 'center' }}
                                >
                                    <Form.Item
                                        {...field}
                                        label={index === 0 ? 'Source' : ''}
                                        name={[field.name, 'source']}
                                        fieldKey={[field.fieldKey!, 'source']}
                                        rules={[{ required: true, message: 'Missing source' }]}
                                        style={{ flex: 1, marginRight: 8 }}
                                    >
                                        <Input placeholder="Source System" />
                                    </Form.Item>
                                    <Form.Item
                                        {...field}
                                        label={index === 0 ? 'Target' : ''}
                                        name={[field.name, 'target']}
                                        fieldKey={[field.fieldKey!, 'target']}
                                        rules={[{ required: true, message: 'Missing target' }]}
                                        style={{ flex: 1, marginRight: 8 }}
                                    >
                                        <Input placeholder="Target Name" />
                                    </Form.Item>
                                    <Button type="link" onClick={() => remove(field.name)}>
                                        Remove
                                    </Button>
                                </div>
                            ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block>
                                    Add Mapping Entry
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>

                <Form.Item style={{ marginTop: 20 }}>
                    <Button type="primary" htmlType="submit">
                        {mappingExists ? 'Update Mapping' : 'Create Mapping'}
                    </Button>
                </Form.Item>
            </Form>
        </BoundedContainer>
    );
};

export default MappingPage;
