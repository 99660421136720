import React from 'react';
import { Form, Input, Button, Tabs, Switch } from 'antd';
import axios from 'axios';

const { TabPane } = Tabs;

interface AccountQuery {
    query: string;
}

interface Credentials {
    host: string;
    port: string;
    user: string;
    dbname: string;
    sslmode: string;
    encrypted: boolean;
    account_query: AccountQuery;
}

interface Data {
    environment: string;
    region: string;
}

export interface SystemFormValues {
    name: string;
    type: string;
    credentials: Credentials;
    secureCredentials: string[];
    data: Data;
}

const CreateSystemForm: React.FC = () => {
    const [form] = Form.useForm<SystemFormValues>();

    const onFinish = async (values: SystemFormValues) => {
        try {
            const response = await axios.post('{{url}}/api/system/create', values);
            console.log('Success:', response.data);
            // Optionally, display a success message or handle further actions.
        } catch (error) {
            console.error('Error creating system:', error);
            // Optionally, display error notification.
        }
    };

    return (
        <Form<SystemFormValues>
            form={form}
    layout="vertical"
    initialValues={{
        name: "test-database",
            type: "database",
            credentials: {
            host: "aoh-dev-db.postgres.database.azure.com",
                port: "5432",
                user: "database_user",
                dbname: "identity_source",
                sslmode: "require",
                encrypted: true,
                account_query: {
                query: "SELECT user_id, username, first_name FROM users",
            },
        },
        secureCredentials: ["password"],
            data: {
            environment: "production",
                region: "us-east-1",
        },
    }}
    onFinish={onFinish}
    >
    <Tabs defaultActiveKey="1">
        {/* General Information */}
        <TabPane tab="General" key="1">
    <Form.Item
        label="Name"
    name="name"
    rules={[{ required: true, message: 'Please input the name!' }]}
        >
        <Input />
        </Form.Item>
        <Form.Item
    label="Type"
    name="type"
    rules={[{ required: true, message: 'Please input the type!' }]}
        >
        <Input />
        </Form.Item>
        </TabPane>

    {/* Credentials (determined by type "database") */}
    <TabPane tab="Credentials" key="2">
    <Form.Item
        label="Host"
    name={['credentials', 'host']}
    rules={[{ required: true, message: 'Please input host!' }]}
        >
        <Input />
        </Form.Item>
        <Form.Item
    label="Port"
    name={['credentials', 'port']}
    rules={[{ required: true, message: 'Please input port!' }]}
        >
        <Input />
        </Form.Item>
        <Form.Item
    label="User"
    name={['credentials', 'user']}
    rules={[{ required: true, message: 'Please input user!' }]}
        >
        <Input />
        </Form.Item>
        <Form.Item
    label="Database Name"
    name={['credentials', 'dbname']}
    rules={[{ required: true, message: 'Please input database name!' }]}
        >
        <Input />
        </Form.Item>
        <Form.Item
    label="SSL Mode"
    name={['credentials', 'sslmode']}
    rules={[{ required: true, message: 'Please input SSL mode!' }]}
        >
        <Input />
        </Form.Item>
        <Form.Item
    label="Encrypted"
    name={['credentials', 'encrypted']}
    valuePropName="checked"
    >
    <Switch checkedChildren="Yes" unCheckedChildren="No" />
        </Form.Item>
        <Form.Item
    label="Account Query"
    name={['credentials', 'account_query', 'query']}
    rules={[{ required: true, message: 'Please input account query!' }]}
    >
    <Input.TextArea rows={4} />
    </Form.Item>
    </TabPane>

    {/* Secure Credentials */}
    <TabPane tab="Secure Credentials" key="3">
    <Form.List name="secureCredentials">
        {(fields, { add, remove }) => (
        <>
            {fields.map((field) => (
                    <Form.Item
                        label={`Secure Credential ${field.name + 1}`}
    required
    key={field.key}
        >
        <Form.Item
            {...field}
    noStyle
    rules={[{ required: true, message: 'Missing secure credential' }]}
        >
        <Input />
        </Form.Item>
        <Button type="link" onClick={() => remove(field.name)}>
    Remove
    </Button>
    </Form.Item>
))}
    <Form.Item>
        <Button type="dashed" onClick={() => add()} block>
    Add Secure Credential
    </Button>
    </Form.Item>
    </>
)}
    </Form.List>
    </TabPane>

    {/* Additional Data */}
    <TabPane tab="Data" key="4">
    <Form.Item
        label="Environment"
    name={['data', 'environment']}
    rules={[{ required: true, message: 'Please input environment!' }]}
        >
        <Input />
        </Form.Item>
        <Form.Item
    label="Region"
    name={['data', 'region']}
    rules={[{ required: true, message: 'Please input region!' }]}
        >
        <Input />
        </Form.Item>
        </TabPane>
        </Tabs>
        <Form.Item style={{ marginTop: 20 }}>
    <Button type="primary" htmlType="submit">
        Create System
    </Button>
    </Form.Item>
    </Form>
);
};

export default CreateSystemForm;
