import * as React from "react";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {Dialog} from "@mui/material";

interface Data {
    columns: GridColDef[];
    rows: any[];
}

interface TableModalProps {
    isOpen: boolean;
    handleOpen: () => void;
    handleClose: () => void;
    data: Data;
}

const paginationModel = { page: 0, pageSize: 5 };

const TableDialog: React.FC<TableModalProps> = ({isOpen, handleOpen, handleClose, data}) => {
    return (
        <div>
            <Dialog open={isOpen} onClose={handleClose}>
                <DataGrid
                    rows={data.rows}
                    columns={data.columns}
                    initialState={{ pagination: { paginationModel } }}
                    pageSizeOptions={[5, 10]}
                    checkboxSelection
                    sx={{ border: 0 }}
                />
            </Dialog>
        </div>
    );
}

export default TableDialog;